.dropdown {
  /* float: left; */
  text-align: center;
  overflow: hidden;
  /* width: fit-content; */
}

.dropdown .dropbtn {
  font-size: 12px;    
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  white-space: nowrap;
  cursor: pointer;
  text-align: initial;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 112px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  cursor: pointer;
}

.dropdown-content a {
  float: none;
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  cursor: pointer;
}
.MuiSelect-icon {
  color: #D39B2D !important;
}

.topnav a:hover, .dropdown:hover .dropbtn {

  color: white;
  /* border-bottom: 3px solid black; */
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
  cursor: pointer;
}
.MuiSelect-select.MuiSelect-select{
  padding-left: 5px !important;
}
.upperHeader1::-webkit-scrollbar {
  display: none !important; /* Chrome, Safari, and Opera */
}

